.sh-modal {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.6);
}

.sh-modal button {
  outline: none;
  cursor: pointer;
  border: 0;
}

.sh-modal > section {
  width: 90%;
  max-width: 512px;
  margin: 0 auto;
  border-radius: 0.3rem;
  background-color: #fff;
  /* 팝업이 열릴 때 스르륵 열리는 효과 */
  animation: modal-show 0.3s;
  overflow: hidden;
}

.sh-modal > section > header {
  position: relative;
  padding: 16px 64px 16px 16px;
  background-color: #f1f1f1;
  font-weight: 700;
}

.sh-modal > section > header button {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 30px;
  font-size: 21px;
  font-weight: 700;
  text-align: center;
  color: #999;
  background-color: transparent;
}

.sh-modal > section > main {
  max-height: 77.5vh;
  padding: calc(4px * 4);
  overflow-y: auto;
  word-wrap: break-word;
  border-top: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
}

.sh-modal > section > footer {
  padding: 12px 16px;
  text-align: right;
}

.sh-modal > section > footer button {
  padding: 6px 12px;
  color: #fff;
  background-color: #6c757d;
  border-radius: 5px;
  font-size: 13px;
}

.sh-modal > section.closing {
  animation: modal-hide 0.3s;
}

.sh-modal.openModal {
  display: flex;
  align-items: center;
  /* 팝업이 열릴 때 스르륵 열리는 효과 */
  animation: modal-bg-show 0.3s;
}

.sh-modal.openModal.closing {
  animation: modal-bg-hide 0.3s;
}

@keyframes modal-show {
  from {
    opacity: 0;
    margin-top: -50px;
  }
  to {
    opacity: 1;
    margin-top: 0;
  }
}

@keyframes modal-hide {
  from {
    opacity: 1;
    margin-top: 0;
  }
  to {
    opacity: 0;
    margin-top: -50px;
  }
}

@keyframes modal-bg-show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes modal-bg-hide {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.sh-modal.sh-modal--large > section {
  max-width: 1300px;
  height: 810px;
  .sh-modal > section > footer {
    padding: 10px 16px;
    text-align: right;
  }

  header {
    font-size: 18px;
  }

  footer {
    padding: 8px 16px;
  }

  footer button {
    padding: 10px 20px;

    font-size: 16px;
  }
}
